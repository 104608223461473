import React from "react"

import SEO from "../components/seo"

import ProductsArmariosVitrines from "../components/products/products-armarios-vitrines"

const ArmarioVitrines = () => (
  <>
    <SEO title="Armários Vitrines" />

    <ProductsArmariosVitrines />
  </>
)

export default ArmarioVitrines
