import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { StyledContainer, Product, ProductContent } from "./styles"

SwiperCore.use([Navigation, Pagination])

const ProductsArmariosVitrines = () => {
  const data = useStaticQuery(graphql`
    query {
      armariovitrine: file(
        relativePath: { eq: "products/armario-vitrine.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      armariovitrine2: file(
        relativePath: { eq: "products/armario-vitrine2.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      armariovitrine3: file(
        relativePath: { eq: "products/armario-vitrine3.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      armariovitrine4: file(
        relativePath: { eq: "products/armario-vitrine4.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      armariovitrine5: file(
        relativePath: { eq: "products/armario-vitrine5.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledContainer as="section">
      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          navigation
        >
          <SwiperSlide>
            <Img fluid={data.armariovitrine.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.armariovitrine2.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.armariovitrine3.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.armariovitrine4.childImageSharp.fluid} />
          </SwiperSlide>
          <SwiperSlide>
            <Img fluid={data.armariovitrine5.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Armário Vitrine</h3>

          <ul>
            <li>Chapa: 26</li>
            <li>Altura: 150 cm </li>
            <li>Largura: 50 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>{/* <strong>R$ 720,00</strong> */}</li>
            <li>---</li>
            <li>Altura: 165 cm</li>
            <li>Largura: 65 cm</li>
            <li>Profundidade: 40 cm</li>
            <li>{/* <strong>R$ 753,00</strong> */}</li>
          </ul>

          {/* <p className="item-price">
            R$ 720,<span>00</span>
          </p>
          <p className="item-parcel">
            em até 10x sem juros no cartão de crédito
          </p> */}
        </ProductContent>
      </Product>
    </StyledContainer>
  )
}

export default ProductsArmariosVitrines
